/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import AsyncStorage from "@react-native-community/async-storage";

import { StorageContent } from "../../models";

import { IStorageManager } from "./IStorageManager";

export class StorageManagerMobile implements IStorageManager {
  public setValue = async (key: string, val: any) => {
    // Let's convert `undefined` values to `null` to get the value consistent
    if (val === undefined) {
      val = null;
    } else {
      val = JSON.stringify(val);
    }

    try {
      return await AsyncStorage.setItem(key, val);
    } catch (e) {
      return undefined;
    }
  };

  public getValue = async (key: keyof StorageContent) => {
    try {
      const item = await AsyncStorage.getItem(key);

      if (item) {
        return JSON.parse(item);
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  };

  public deleteValue = async (name: keyof StorageContent) => {
    try {
      return await AsyncStorage.removeItem(name);
    } catch (e) {
      return undefined;
    }
  };

  public clearAll = async () => {
    try {
      return await AsyncStorage.clear();
    } catch (e) {
      return undefined;
    }
  };
}
