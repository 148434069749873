/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export enum FooterAlignment {
  Justify = "justify",
  FitToContent = "fit_to_content",
}
