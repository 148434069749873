/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AuthStore,
  DeviceHelper,
  IAppState,
  IRegisterConfirmEmailModel,
  IUserDeviceModel,
  RouteHelper,
  UrlHelper,
  useIsLoggedIn,
} from "@xala/common";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";

import { IAuthScreenProps } from "screens/AuthScreen/AuthScreen";

import { Form, FormButton, FormLine } from "../../Form";
import "../../Form/Form.scss";
import { LoaderSpinner } from "../../LoaderSpinner";
import "../AuthForm.scss";

import "./ConfirmEmail.scss";

interface IConfirmEmailProps extends IAuthScreenProps, RouteComponentProps {}

interface IConfirmEmailQueryParams {
  email?: string;
  token?: string;
}

export const ConfirmEmail = (_: IConfirmEmailProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [device, setDevice] = useState<IUserDeviceModel | undefined>();
  const [queryParams] = useState<IConfirmEmailQueryParams>(
    UrlHelper.parse(location.search)
  );
  const { isTokenValid, isProcessing, error } = useSelector(
    (state: IAppState) => state.auth
  );
  const isUserLoggedIn = useIsLoggedIn();

  useEffect(() => {
    dispatch(AuthStore.Actions.validateToken(queryParams.token || ""));
    DeviceHelper.getDeviceInfo().then((device) => {
      setDevice(device);
    });
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (device && isTokenValid) {
      const payload: IRegisterConfirmEmailModel = {
        Email: queryParams.email || "",
        Token: queryParams.token || "",
        Device: device,
      };
      const currentLocation = `${location.pathname}${location.search}`;
      dispatch(
        AuthStore.Actions.registerConfirmEmail(payload, currentLocation)
      );
    }
  }, [dispatch, device, isTokenValid, queryParams]);

  const handleConfirmationSuccessForm = () => {
    isUserLoggedIn ? RouteHelper.goToHome() : RouteHelper.goToLogin();
  };

  const handleConfirmationErrorForm = () => {
    queryParams.email &&
      dispatch(
        AuthStore.Actions.resendConfirmationEmailByUser({
          email: queryParams.email,
        })
      );
  };

  const renderConfirmationSuccess = () => {
    return (
      <Form onFinish={handleConfirmationSuccessForm}>
        <h1 className="text-upper text-center">{t("CONFIRM_EMAIL__TITLE")}</h1>
        <p className="text-center text-large">{t("CONFIRM_EMAIL__SUBTITLE")}</p>
        <FormLine />
        <p className="text-center text-large">{t("CONFIRM_EMAIL__MESSAGE")}</p>
        <FormButton>{t("CONFIRM_EMAIL__SUBMIT")}</FormButton>
      </Form>
    );
  };

  const renderConfirmationError = () => {
    return (
      <Form onFinish={handleConfirmationErrorForm}>
        <h1 className="text-upper text-center">{t("RESEND_TOKEN__TITLE")}</h1>
        <p className="text-center text-large">
          {t("RESEND_TOKEN__SUBTITLE_1")}
        </p>
        <p className="text-center text-large">
          {t("RESEND_TOKEN__SUBTITLE_2")}
        </p>
        <FormLine />
        <FormButton>{t("RESEND_TOKEN__SUBMIT")}</FormButton>
      </Form>
    );
  };

  const renderLoader = () => {
    return (
      <div className="form" style={{ textAlign: "center" }}>
        <LoaderSpinner />
      </div>
    );
  };

  return (
    <div className="ConfirmEmail AuthForm">
      {isProcessing
        ? renderLoader()
        : isTokenValid && !error
        ? renderConfirmationSuccess()
        : renderConfirmationError()}
    </div>
  );
};
