/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { EpgScrollContainerProvider, useEpgConfiguration } from "@xala/common";
import React, { useRef } from "react";

import { Container, DaySwitcher, ScrollBox } from "./components";

interface EpgDesktopViewProps {
  children: React.ReactNode;
}

export const EpgDesktopView = (props: EpgDesktopViewProps) => {
  const { children } = props;

  const { daySwitcher, useCompactMode } = useEpgConfiguration();

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollBoxRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const showDaySwitcher = Boolean(daySwitcher);
  const timelineHeight = useCompactMode ? 44 : 84;

  return (
    <>
      {children}
      {showDaySwitcher && <DaySwitcher />}
      <EpgScrollContainerProvider
        containerRef={containerRef}
        scrollBoxRef={scrollBoxRef}
        sidebarRef={sidebarRef}
      >
        <Container ref={containerRef} timelineHeight={timelineHeight}>
          <ScrollBox
            scrollBoxRef={scrollBoxRef}
            sidebarRef={sidebarRef}
            timelineHeight={timelineHeight}
          />
        </Container>
      </EpgScrollContainerProvider>
    </>
  );
};
