/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AuthStore,
  buildEmailRule,
  buildRequiredRule,
  dispatch,
  IForgotPasswordModel,
  IFormValues,
  useAnalyticsContext,
} from "@xala/common";
import React from "react";
import { useTranslation } from "react-i18next";

import { Form, FormButton, LabelField } from "../../Form";
import { Input } from "../../Input";
import "../../Form/Form.scss";
import "../AuthForm.scss";

import "./ForgotPasswordForm.scss";

export const ForgotPasswordForm = () => {
  const { t } = useTranslation();

  const { requestPasswordRecovery } = useAnalyticsContext();

  const onFinish = (values: IFormValues) => {
    const payload: IForgotPasswordModel = {
      Email: values["email"],
    };

    requestPasswordRecovery();

    dispatch(AuthStore.Actions.forgotPassword(payload));
  };

  const onCancel = () => {
    window.history.back();
  };

  return (
    <div className="ForgotPasswordForm AuthForm">
      <Form onFinish={onFinish}>
        <h1>{t("FORGOT_PASSWORD__TITLE")}</h1>
        <p>{t("SETTINGS_PROFILE__PASSWORD_DESCRIPTION")}</p>
        <LabelField
          name="email"
          rules={[buildRequiredRule(), buildEmailRule()]}
        >
          <Input className="FormInput" placeholder={t("LOGIN__EMAIL_LABEL")} />
        </LabelField>

        <div className="ButtonLine">
          <FormButton>{t("FORGOT_PASSWORD__SUBMIT")}</FormButton>
        </div>

        <p className="text-center ForgotPasswordBack" onClick={onCancel}>
          <span className="text-link text-underline">
            {t("COMMON__BUTTON_GO_BACK")}
          </span>
        </p>
      </Form>
    </div>
  );
};
