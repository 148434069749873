/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum ActionType {
  OpenScreen = "OPEN_SCREEN",
  OpenUrl = "OPEN_URL",
  CallTo = "CALL_TO",
  EmailTo = "EMAIL_TO",
}
