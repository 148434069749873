/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
// eslint-disable-next-line import/namespace
import { Platform } from "react-native";

import { StorageManagerCommon } from "./StorageManager.common";
import { StorageManagerMobile } from "./StorageManager.mobile";

const StorageManager =
  Platform.OS === "android" || Platform.OS === "ios"
    ? StorageManagerMobile
    : StorageManagerCommon;

export default new StorageManager();
