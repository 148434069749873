/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
// eslint-disable-next-line import/namespace
import { Platform } from "react-native";

import { KeyboardKeyCode, PlatformType } from "../enums";

declare global {
  interface Window {
    PalmSystem: any;

    tizen: any;

    webOS: any;
  }
}

export class PlatformHelper {
  static isWeb = () => {
    return Platform.OS === "web";
  };

  static isTV = () => {
    return (
      Platform.isTV === true ||
      PlatformHelper.isTizen() ||
      PlatformHelper.isWebos()
    );
  };

  static isAndroid = () => {
    return Platform.OS === "android";
  };

  static isIOS = () => {
    return Platform.OS === "ios";
  };

  static isTizen = () => {
    return !!(window && window.tizen);
  };

  static isWebos = () => {
    return window?.webOS?.platform.tv;
  };

  static closeApp = () => {
    if (PlatformHelper.isTizen()) {
      window.tizen.application.getCurrentApplication().exit();
    } else {
      window.close();
    }
  };

  static registerKeyboardKeys(): void {
    const platform = this.Platform;

    switch (platform) {
      case PlatformType.Tizen:
        window.tizen.tvinputdevice.registerKey("MediaRewind");
        window.tizen.tvinputdevice.registerKey("MediaFastForward");
        window.tizen.tvinputdevice.registerKey("MediaPlay");
        window.tizen.tvinputdevice.registerKey("MediaPause");
        window.tizen.tvinputdevice.registerKey("MediaStop");
        window.tizen.tvinputdevice.registerKey("0");
        window.tizen.tvinputdevice.registerKey("1");
        window.tizen.tvinputdevice.registerKey("2");
        window.tizen.tvinputdevice.registerKey("3");
        window.tizen.tvinputdevice.registerKey("4");
        window.tizen.tvinputdevice.registerKey("5");
        window.tizen.tvinputdevice.registerKey("6");
        window.tizen.tvinputdevice.registerKey("7");
        window.tizen.tvinputdevice.registerKey("8");
        window.tizen.tvinputdevice.registerKey("9");
        break;
      default:
        break;
    }
  }

  static getKeyboardKeyCode(key: number): KeyboardKeyCode {
    const platform = this.Platform;

    switch (platform) {
      case PlatformType.Tizen:
        switch (key) {
          case 13:
            return KeyboardKeyCode.Enter;
          case 19:
            return KeyboardKeyCode.Pause;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          case 48:
            return KeyboardKeyCode.Zero;
          case 49:
            return KeyboardKeyCode.One;
          case 50:
            return KeyboardKeyCode.Two;
          case 51:
            return KeyboardKeyCode.Three;
          case 52:
            return KeyboardKeyCode.Four;
          case 53:
            return KeyboardKeyCode.Five;
          case 54:
            return KeyboardKeyCode.Six;
          case 55:
            return KeyboardKeyCode.Seven;
          case 56:
            return KeyboardKeyCode.Eight;
          case 57:
            return KeyboardKeyCode.Nine;
          case 412:
            return KeyboardKeyCode.Rewind;
          case 413:
            return KeyboardKeyCode.Stop;
          case 415:
            return KeyboardKeyCode.Play;
          case 417:
            return KeyboardKeyCode.FastForward;
          case 10009:
            return KeyboardKeyCode.Back;
          case 10252:
            return KeyboardKeyCode.PlayPause;
          default:
            return KeyboardKeyCode.Undefined;
        }
      case PlatformType.WebOS:
        switch (key) {
          case 8:
            return KeyboardKeyCode.Back;
          case 13:
            return KeyboardKeyCode.Enter;
          case 19:
            return KeyboardKeyCode.Pause;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          case 412:
            return KeyboardKeyCode.Rewind;
          case 413:
            return KeyboardKeyCode.Stop;
          case 415:
            return KeyboardKeyCode.Play;
          case 417:
            return KeyboardKeyCode.FastForward;
          case 461:
            return KeyboardKeyCode.Back;
          case 10252:
            return KeyboardKeyCode.PlayPause;
          default:
            return KeyboardKeyCode.Undefined;
        }
      default:
        switch (key) {
          case 8:
            return KeyboardKeyCode.Back;
          case 13:
            return KeyboardKeyCode.Enter;
          case 27:
            return KeyboardKeyCode.ESC;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          default:
            return KeyboardKeyCode.Undefined;
        }
    }
  }

  static get Platform(): PlatformType {
    if (PlatformHelper.isTizen()) {
      return PlatformType.Tizen;
    } else if (PlatformHelper.isWebos()) {
      return PlatformType.WebOS;
    }

    if (Platform.isTV === true) {
      if (PlatformHelper.isAndroid()) {
        return PlatformType.AndroidTV;
      } else if (PlatformHelper.isIOS()) {
        return PlatformType.AppleTV;
      }
    }

    if (PlatformHelper.isAndroid()) {
      return PlatformType.AndroidPhone;
    } else if (PlatformHelper.isIOS()) {
      return PlatformType.iOSPhone;
    }

    return PlatformType.Web;
  }

  static getPlatformByCode = (code: string): PlatformType | undefined => {
    switch (code) {
      case "ANY":
        return PlatformType.Any;
      case "ANDROID_PHONE":
        return PlatformType.AndroidPhone;
      case "ANDROID_TABLET":
        return PlatformType.AndroidTablet;
      case "ANDROID_TV":
        return PlatformType.AndroidTV;
      case "APPLE_TV":
        return PlatformType.AppleTV;
      case "IOS_PHONE":
        return PlatformType.iOSPhone;
      case "IPAD":
        return PlatformType.iPad;
      case "TIZEN":
        return PlatformType.Tizen;
      case "WEB":
        return PlatformType.Web;
      case "WEB_OS":
        return PlatformType.WebOS;
      case "ROKU":
        return PlatformType.Roku;
      default:
        return undefined;
    }
  };

  static getPlatformVersion = (): string => {
    let version = "1.0";

    switch (PlatformHelper.Platform) {
      case PlatformType.Tizen:
        if (typeof window.tizen != "undefined") {
          try {
            const platform = window.tizen.systeminfo.getCapability(
              "http://tizen.org/feature/platform.version"
            );
            version = `${platform}`;
          } catch (error) {
            console.error(
              "Get Platform Version Error: ",
              JSON.stringify(error)
            );
          }
        }
        break;
      case PlatformType.iOSPhone:
        version = Platform.Version.toString();
        break;
    }

    return version;
  };

  static getPlatformVersionNumber = (): number => {
    const version = PlatformHelper.getPlatformVersion();

    const versionNumber = parseFloat(version);

    return versionNumber;
  };
}
