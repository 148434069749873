/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  MediaType,
  RouteHelper,
  TimeHelper,
  useAnalyticsContext,
  useIsPlayerAvailable,
  useTheme,
} from "@xala/common";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SkeletonTheme } from "react-loading-skeleton";

import { useElementSize, useHoverState, useStylesOnHover } from "hooks";
import PlayIcon from "resources/icons/play.svg";

import { IListComponentItemProps } from "../../models";

import "./ListComponentItemPodcast.scss";
import { useResponsivePodcastStyles } from "./useResponsivePodcastStyles";

import { Label } from "components";

const itemMargin = 12;

export const ListComponentItemPodcast = ({
  media,
  isActive,
  isGrid,
}: IListComponentItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const themeProvider = useTheme();

  const itemWidth = useElementSize(ref);

  const { isHover, onMouseEnter, onMouseLeave } = useHoverState();
  const { recoClick } = useAnalyticsContext();

  const stylesOnHover = useStylesOnHover(
    isActive,
    isHover,
    itemWidth,
    itemMargin
  );

  const { isStreamable, isSourceAvailable, isAvailable } =
    useIsPlayerAvailable(media);

  const isContentAvailableToPlay =
    isStreamable && isSourceAvailable && isAvailable;

  const getDuration = useMemo(() => {
    return TimeHelper.formatFromTo(media?.Duration);
  }, [media]);

  const {
    iconStyles,
    durationStyles,
    descriptionStyles,
    titleStyles,
    dateStyles,
    containerStyles,
  } = useResponsivePodcastStyles({
    isGrid,
    isActive,
    isHover,
    itemWidth,
    itemMargin,
  });

  const { i18n } = useTranslation();

  const getDate = useMemo(() => {
    return TimeHelper.getTranslatedStartDate(
      media?.AvailableFrom,
      i18n.language,
      "D MMM YYYY"
    );
  }, [i18n.language, media?.AvailableFrom]);

  const renderSkeleton = () => {
    const skeletonColor = themeProvider.getSkeletonColor();
    return (
      <div className="ListComponentItemFrame-container ListComponentItemFrame-container--skeleton">
        <SkeletonTheme color={skeletonColor} highlightColor={skeletonColor}>
          <div className="ListComponentItemFrame-skeleton" />
        </SkeletonTheme>
      </div>
    );
  };
  if (!media) {
    return renderSkeleton();
  }

  const routeHandlerToDetails = () => {
    recoClick(media);

    return RouteHelper.goToDetails(media);
  };

  const routeHandlerToPlayer = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (media.MediaTypeCode === MediaType.Podcast && isContentAvailableToPlay) {
      recoClick(media);

      return RouteHelper.goToPlayer(media);
    }
  };

  return (
    <>
      <div
        className="ListComponentPodcastItem"
        onClick={routeHandlerToDetails}
        style={{ ...stylesOnHover, ...containerStyles }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        <Label media={media} />
        <div>
          <div
            className="ListComponentPodcastItem-info-date"
            style={dateStyles}
          >
            {getDate}
          </div>
          <div
            className="ListComponentPodcastItem-info-title"
            style={titleStyles}
          >
            {media.Title}
          </div>
          <div
            className="ListComponentPodcastItem-info-description"
            style={descriptionStyles}
            dangerouslySetInnerHTML={{
              __html: media.ShortDescription || "",
            }}
          ></div>

          {isContentAvailableToPlay && (
            <div className="ListComponentPodcastItem-info-buttonSection">
              <div
                className="ListComponentPodcastItem-info-buttonSection-icon"
                style={iconStyles}
                onClick={routeHandlerToPlayer}
              >
                <PlayIcon />
              </div>
              <div
                className="ListComponentPodcastItem-info-buttonSection-duration"
                style={durationStyles}
              >
                {getDuration}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
