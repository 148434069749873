/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppConfig } from "../../../app/AppConfig.mobile";

import { MOBILE } from "./konodrac.constants";
import { IGetEnvVariables } from "./konodrac.types";

export const getEnvVariables = (system: string): IGetEnvVariables => {
  const DEFAULT_DATASET_ID =
    system !== MOBILE
      ? (process.env.REACT_APP_KONODRAC_ANALYTICS_DATASETID as string)
      : AppConfig.KonodracDataSetId;
  const DEFAULT_CHANNEL_NAME =
    system !== MOBILE
      ? (process.env.REACT_APP_KONODRAC_DEFAULT_CHANNEL_NAME as string)
      : AppConfig.KonodracDefaultChannelName;
  const DEFAULT_CHANNEL_ID =
    system !== MOBILE
      ? (process.env.REACT_APP_KONODRAC_DEFAULT_CHANNELID as string)
      : AppConfig.KonodracDefaultChannelId;
  const isEnabled = Boolean(DEFAULT_DATASET_ID);

  return {
    DEFAULT_DATASET_ID,
    DEFAULT_CHANNEL_NAME,
    DEFAULT_CHANNEL_ID,
    isEnabled,
  };
};
