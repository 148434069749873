/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum RegistrationSteps {
  One = "STEP_ONE",
  Two = "STEP_TWO",
  Error = "ERROR",
}
