/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  CellType,
  MediaStreamType,
  useGetMediaPlayInfo,
  useIdRouteParam,
  useIsLoggedIn,
  useRecommendationsList,
} from "@xala/common";
import cx from "classnames";
import React, { RefObject, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ClipboardCopyButton,
  ListComponent,
  MediaAdditionalInfo,
  MediaPeople,
  MediaStartInfo,
  MyListButton,
  PlayButton,
  TrailerButton,
} from "components";
import { MediaButtonVariant } from "enums";
import MuteIcon from "resources/icons/mute.svg";
import UnmuteIcon from "resources/icons/unmute.svg";
import {
  MediaDetailsScreen,
  useMediaDetailsLoadingSelector,
  useMediaDetailsMediaSelector,
} from "screens/MediaDetailsScreen";

import "./MovieDetailsScreen.scss";

export const MovieDetailsScreen = () => {
  const muteButton = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const media = useMediaDetailsMediaSelector();
  const loading = useMediaDetailsLoadingSelector();
  const { recommendationsList } = useRecommendationsList({
    media,
    loading,
    cellType: CellType.Frame,
    visibleItemsCount: innerWidth > 1100 ? 3 : 2,
  });

  const renderRecommendationList = useMemo(() => {
    return (
      recommendationsList && (
        <ListComponent
          component={recommendationsList}
          className="RecommendationList"
        />
      )
    );
  }, [recommendationsList]);

  const RenderDetails = ({
    muteButton,
  }: {
    muteButton: RefObject<HTMLButtonElement>;
  }) => {
    const mediaId = useIdRouteParam();
    const [muted, setMuted] = useState<boolean>(true);

    const { playInfo } = useGetMediaPlayInfo({
      mediaId,
      streamType: MediaStreamType.Promo,
    });

    if (!media) {
      return <></>;
    }

    const showGenre = Boolean(media.Genre);
    const showParentSubtitle = Boolean(media.ParentMedia?.ParentMediaTitle);
    const showDescription = Boolean(media?.LongDescription);
    const showPeople = media.People?.length !== 0;

    const changeMuted = (value: boolean) => {
      setMuted(value);
    };

    return (
      <>
        <div className="MovieDetails__info-container">
          <div className="MovieDetails__info">
            <div>
              {showGenre && (
                <div className="MovieDetails__genres">{media.Genre}</div>
              )}
              {showParentSubtitle && (
                <div className="MovieDetails__parentTitle">
                  {media.ParentMedia?.ParentMediaTitle}
                </div>
              )}
            </div>
            <div className="MovieDetails__main-info">
              <div className="MovieDetails__title text--shadow">
                {media.Title}
              </div>
              <MediaAdditionalInfo media={media} />
              <MediaStartInfo media={media} />
              <div className="MovieDetails__actions text--shadow">
                <PlayButton media={media} />
                {isLoggedIn && <MyListButton media={media} />}
                <TrailerButton media={media} />
                <ClipboardCopyButton />
              </div>
            </div>
          </div>
          {playInfo?.ContentUrl && (
            <button
              className={[
                cx("MediaButton", `MediaButton--${MediaButtonVariant.Primary}`),
                "mute-button",
              ].join(", ")}
              ref={muteButton}
              onClick={() => changeMuted(!muted)}
            >
              <i className={cx("MediaButton__icon")}>
                {muted ? <MuteIcon /> : <UnmuteIcon />}
              </i>
            </button>
          )}
        </div>
        <div className="MovieDetails__details-container">
          {showDescription && (
            <>
              <h1 className="MovieDetails__details__description-header">
                {t("DETAILS__INFORMATION", "Information")}
              </h1>
              <div
                className="MovieDetails__details__description-text"
                dangerouslySetInnerHTML={{
                  __html: media.LongDescription || "",
                }}
              ></div>
            </>
          )}
          {showPeople && (
            <>
              <h1 className="MovieDetails__details__people-header">
                {t("DETAILS__TECHNICAL_SHEET", "Technical card")}
              </h1>
              <MediaPeople people={media.People} loading={loading} />
            </>
          )}
        </div>
        {renderRecommendationList}
      </>
    );
  };

  return (
    <MediaDetailsScreen
      className="MovieDetails"
      media={media}
      muteButton={muteButton}
    >
      <RenderDetails muteButton={muteButton} />
    </MediaDetailsScreen>
  );
};
