/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
const PREFIX = "OFFLINE_MODE";
const SET_OFFLINE_MODE = `${PREFIX}_SET_OFFLINE`;
const SET_ONLINE_MODE = `${PREFIX}_SET_ONLINE`;

const GET_DOWNLOADED_PODCASTS = `${PREFIX}_GET_DOWNLOADED_PODCASTS`;
const GET_DOWNLOADED_PODCASTS_SUCCESS = `${PREFIX}_GET_DOWNLOADED_PODCASTS_SUCCESS`;
const GET_DOWNLOADED_PODCASTS_FAILURE = `${PREFIX}_GET_DOWNLOADED_PODCASTS_FAILURE`;

const START_DOWNLOAD = `${PREFIX}_START_DOWNLOAD`;
const START_DOWNLOAD_SUCCESS = `${PREFIX}_START_DOWNLOAD_SUCCESS`;
const START_DOWNLOAD_FAILED = `${PREFIX}_START_DOWNLOAD_FAILED`;
const SET_DOWNLOAD_PROGRESS = `${PREFIX}_SET_DOWNLOAD_PROGRESS`;

const UPDATE_DOWNLOADED_MEDIA = `${PREFIX}_UPDATE_DOWNLOADED_MEDIA`;

const ADD_TO_QUEUE = `${PREFIX}_ADD_TO_QUEUE`;
const ADD_JOB_ID = `${PREFIX}_ADD_JOB_ID`;

export const ActionTypes = {
  SET_OFFLINE_MODE,
  SET_ONLINE_MODE,
  GET_DOWNLOADED_PODCASTS,
  GET_DOWNLOADED_PODCASTS_SUCCESS,
  GET_DOWNLOADED_PODCASTS_FAILURE,
  START_DOWNLOAD,
  START_DOWNLOAD_SUCCESS,
  START_DOWNLOAD_FAILED,
  SET_DOWNLOAD_PROGRESS,
  UPDATE_DOWNLOADED_MEDIA,
  ADD_TO_QUEUE,
  ADD_JOB_ID,
};
