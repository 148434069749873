/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum PlayerType {
  THEOPlayer = "THEOPLAYER",
  TheBetterPlayer = "THEBETTERPLAYER",
  VideoJsPlayer = "VIDEOJSPLAYER",
  Mycujoo = "MYCUJOO",
  BrightcovePlayer = "BRIGHTCOVEPLAYER",
}
