/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import * as React from "react";

import "./ImageOverlay.scss";

export interface ImageOverlayProps {
  src?: string;
  imageStyle?: React.CSSProperties;
}

export const ImageOverlay = ({ src, imageStyle }: ImageOverlayProps) => {
  return (
    <div className="ImageOverlay-container">
      {src ? (
        <img src={src} style={imageStyle} />
      ) : (
        <div className="ImageOverlay-overlay" />
      )}
    </div>
  );
};
