/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Platform } from "react-native";
import { Observable, Observer } from "rxjs";

import { IAnalyticsMarkerModel } from "models";

import { AppConfig } from "../../app/AppConfig.mobile";
import { HTTP_METHOD } from "../../constants";
import { AxiosSubscriber } from "../Common";
export class AnalyticsService {
  public sendAnalyticsMarker = (
    data: IAnalyticsMarkerModel
  ): Observable<null> =>
    new Observable((observer: Observer<null>) => {
      const isMobile = Platform.OS === "ios" || Platform.OS === "android";

      if (isMobile) {
        return new AxiosSubscriber(observer, {
          baseURL:
            (process.env.REACT_APP_KONODRAC_ANALYTICS_URL as string) ||
            AppConfig.KonodracUrl,
          data,
          method: HTTP_METHOD.POST,
          url: "",
          ignoreHeader: true,
          userAgent: data.mark?.userAgent,
        });
      }

      return new AxiosSubscriber(observer, {
        baseURL:
          (process.env.REACT_APP_KONODRAC_ANALYTICS_URL as string) ||
          AppConfig.KonodracUrl,
        data,
        method: HTTP_METHOD.POST,
        url: "",
        ignoreHeader: true,
      });
    });
}
