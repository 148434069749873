/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const ROUTES = {
  MAIN_SCREEN: "/",
  MOVIE_DETAILS_SCREEN: "movie",
  SERIES_DETAILS_SCREEN: "series",
  PLAYER_SCREEN: "play",
  SETTINGS: "settings",
  PROFILE: "profile",
  SETTINGS_MY_ORDERS: "settings/my_orders",
  SETTINGS_TERMS_AND_CONDITIONS: "settings/terms_and_conditions",
  SETTINGS_LANGUAGE: "settings/language",
  SETTINGS_LOGOUT: "settings/logout",
  MY_LIST: "my-list",
  DELETE_ACCOUNT: "delete-account",
  DELETE_ACCOUNT_SUCCESS: "/delete-account-success",
};
