/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel, MediaType, RouteHelper, TimeHelper } from "@xala/common";
import React, { useCallback } from "react";

import { Label } from "components";
import Plus from "resources/icons/Plus.svg";

import "./MediaDetails.scss";

interface IMediaDetailsProps {
  media: IMediaModel;
  isOnHighlight?: boolean;
}

export const MediaDetails = ({ media, isOnHighlight }: IMediaDetailsProps) => {
  const {
    Title,
    Duration,
    MediaTypeCode,
    AvailableFrom,
    AvailableTo,
    StartDateTime,
    EndDateTime,
    ShortDescription,
    Genre,
  } = media || {};

  const showDurationTime = Duration &&
    TimeHelper.isCurrentBetween(AvailableFrom, AvailableTo) && (
      <p className="Container__duration">
        {TimeHelper.formatDuration(Duration)}
      </p>
    );

  const showStartDateTime = StartDateTime &&
    !TimeHelper.isBeforeCurrent(EndDateTime) &&
    EndDateTime && (
      <p className="Container__startDateTime">
        {`${TimeHelper.format(StartDateTime, "HH:mm")}h - ${TimeHelper.format(
          EndDateTime,
          "HH:mm"
        )}h`}
      </p>
    );

  const duration =
    MediaTypeCode === MediaType.Program || MediaTypeCode === MediaType.Live
      ? showStartDateTime
      : showDurationTime;

  const goToDetails = useCallback(
    () => RouteHelper.handleClickFromListComponentItem(media),
    [media]
  );

  return (
    <div className="Container" onClick={goToDetails}>
      <div className="Container__content">
        <div className="Container__wrapper">
          {Genre && <p className="Container__category">{Genre}</p>}
          <Label media={media} isOnHighlight={isOnHighlight} />
        </div>
        <p className="Container__title">{Title}</p>
        <p
          className="Container__description"
          dangerouslySetInnerHTML={{ __html: ShortDescription || "" }}
        />
        {duration}
        <div className="Container__buttons">
          <div className="Container__buttons_redirect">
            <Plus />
          </div>
        </div>
      </div>
    </div>
  );
};
