/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  EpgActiveChannelProvider,
  EpgTimeMarkerProvider,
  useEpgScrollBoxComponent,
} from "@xala/common";
import React, { memo, RefObject } from "react";

import { Line, Row, Sidebar, Timeline } from "./components";
import "./ScrollBox.scss";

interface ScrollBoxForwardProps {
  timelineHeight: number;
  scrollBoxRef: RefObject<HTMLDivElement>;
  sidebarRef: RefObject<HTMLDivElement>;
}

const ScrollBoxRaw = (props: ScrollBoxForwardProps) => {
  const { timelineHeight, scrollBoxRef, sidebarRef } = props;

  const { groupedPrograms, height, onScroll, dayWidth, isLoading } =
    useEpgScrollBoxComponent({
      scrollBoxRef,
    });

  const renderPrograms = (): React.ReactNode => {
    return groupedPrograms.map(
      (group) =>
        group.items && (
          <Row
            key={group.key}
            channelId={group.items[0].program.channelId}
            programs={group.items}
          />
        )
    );
  };

  return (
    <div
      className="epg-desktop-scroll-box"
      ref={scrollBoxRef}
      onScroll={onScroll}
    >
      {!isLoading && (
        <EpgTimeMarkerProvider>
          <Line timelineHeight={timelineHeight} />
          <Timeline height={timelineHeight} />
          <EpgActiveChannelProvider>
            <Sidebar ref={sidebarRef} />
            <div
              className="epg-desktop-scroll-box-content"
              style={{
                width: `${dayWidth}px`,
                height: `${height}px`,
              }}
            >
              {renderPrograms()}
            </div>
          </EpgActiveChannelProvider>
        </EpgTimeMarkerProvider>
      )}
    </div>
  );
};

export const ScrollBox = memo(ScrollBoxRaw);

ScrollBox.whyDidYouRender = {
  customName: "ScrollBox",
};
