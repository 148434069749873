/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { AppConfigDefault } from "./AppConfig.default";
import { AppConfigTizen } from "./AppConfig.tizen";
import { AppConfigWebOS } from "./AppConfig.webos";

let AppConfig: any;

switch (process.env.REACT_APP_PLATFORM_CODE) {
  case "TIZEN":
    AppConfig = AppConfigTizen;
    break;
  case "WEB_OS":
    AppConfig = AppConfigWebOS;
    break;
  default:
    AppConfig = AppConfigDefault;
    break;
}

export { AppConfig };
