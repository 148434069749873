/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IConfigurationModel } from "../../models";

export class DownloadAssetsService {
  static downloadConfigAssets = (configuration: IConfigurationModel) => {
    return configuration;
  };
}
