/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { logEvent, setCurrentScreen } from "firebase/analytics";
import ReactGA from "react-ga";

import { firebaseAnalytics } from "firebaseConfig";

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
const trackingEnabled = trackingId !== undefined && trackingId.length > 0;

export const initGA = () => {
  if (trackingEnabled) {
    ReactGA.initialize(trackingId as string, {
      debug: process.env.NODE_ENV === "development",
      titleCase: false,
    });
  }
};

export const trackPageView = () => {
  if (trackingEnabled) {
    ReactGA.set({
      page: window.location.pathname + window.location.search,
    });

    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  if (firebaseAnalytics) {
    setCurrentScreen(
      firebaseAnalytics,
      window.location.pathname + window.location.search
    );
    logEvent(firebaseAnalytics, "screen_view");
  }
};

export const trackEvent = (category: string, action: string, label: string) => {
  if (trackingEnabled) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }

  if (firebaseAnalytics) {
    logEvent(firebaseAnalytics, action, {
      category,
      label,
    });
  }
};
