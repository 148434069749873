/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import Config from "react-native-config";

export class AppConfig {
  static get ApiUrl(): string {
    return `${Config.REACT_APP_API_URL}`;
  }

  static get BrightcoveAccountId(): string {
    return `${Config.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}`;
  }

  static get BrigthcovePolicyKey(): string {
    return `${Config.REACT_APP_BRIGHTCOVE_PLAYER_POLICY_KEY}`;
  }

  static get DataProvider(): string {
    return `${Config.REACT_APP_DATA_PROVIDER}`;
  }

  static get PaymentProvider(): string {
    return `${Config.REACT_APP_PAYMENT_PROVIDER}`;
  }

  static get AppName(): string {
    return `${Config.REACT_APP_NAME}`;
  }

  static get AppUrl(): string {
    return `${Config.REACT_APP_APP_URL}`;
  }

  static get DeviceOrientation(): string {
    return `${Config.REACT_APP_FORCE_ORIENTATION}`;
  }

  static get Environment(): string {
    return `${Config.REACT_APP_ENV}`;
  }

  static get PlayerType(): string {
    return `${Config.REACT_APP_PLAYER_TYPE}`;
  }

  static get TenantOrigin(): string {
    return `${Config.REACT_APP_TENANT_ORIGIN_URL}`;
  }
  static get KonodracDataSetId(): string {
    return `${Config.REACT_APP_KONODRAC_ANALYTICS_DATASETID}`;
  }
  static get KonodracDefaultChannelName(): string {
    return `${Config.REACT_APP_KONODRAC_DEFAULT_CHANNEL_NAME}`;
  }
  static get KonodracDefaultChannelId(): string {
    return `${Config.REACT_APP_KONODRAC_DEFAULT_CHANNELID}`;
  }
  static get KonodracUrl(): string {
    return `${Config.REACT_APP_KONODRAC_ANALYTICS_URL}`;
  }
  static get AnalyticsDomain(): string {
    return `${Config.REACT_APP_ANALYTICS_DOMAIN}`;
  }
  static get DidomiID() {
    return `${Config.REACT_APP_DIDOMI_ID}`;
  }
  static get DidomiToken(): string {
    return `${Config.REACT_APP_DIDOMI_TOKEN}`;
  }
  static get AppEnvName(): string {
    return `${Config.REACT_APP_ENV_NAME}`;
  }
  static get FirebaseToken(): string {
    return `${Config.REACT_APP_FIREBASE_TOKEN}`;
  }
}
