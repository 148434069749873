/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { convertIntoShorterText } from "helpers/textHelper";

import "./Label.scss";

import { IMediaModel, TimeHelper } from "@xala/common";
import cx from "classnames";

export interface LabelProps {
  media: IMediaModel;
  isOnHighlight?: boolean;
}

export const Label = ({ media, isOnHighlight }: LabelProps) => {
  const { t } = useTranslation();
  const isLive = TimeHelper.isCurrentBetween(
    media?.StartDateTime,
    media?.EndDateTime
  );
  const showLabel = isLive || !!media?.Label;

  const labelText = isLive
    ? convertIntoShorterText(t("ASSET_TYPE_LIVE"), 10)
    : convertIntoShorterText(t(media?.Label as string), 10);

  if (!showLabel) return null;

  const labelClassName = cx("label", {
    highlightLabel: isOnHighlight,
  });

  return (
    <div className={labelClassName}>
      <div className="label__text">{labelText}</div>
      {isLive && <div className="label__circle"></div>}
    </div>
  );
};
