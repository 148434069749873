/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  CellType,
  MediaType,
  ScreenType,
  useIsLoggedIn,
  useRecommendationsList,
} from "@xala/common";
import cx from "classnames";
import React, { useMemo } from "react";

import { ClipboardCopyButton, ListComponent, PlayButton } from "components";
import { MyListButton } from "components/MyListButton";

import {
  MediaDetailsScreen,
  SeasonEpisodesPicker,
  useMediaDetailsLoadingSelector,
  useMediaDetailsMediaSelector,
} from "../MediaDetailsScreen";

import "./AlbumDetailsScreen.scss";

export const AlbumDetailsScreen = () => {
  const media = useMediaDetailsMediaSelector();
  const loading = useMediaDetailsLoadingSelector();
  const isLoggedIn = useIsLoggedIn();
  const { recommendationsList } = useRecommendationsList({
    media,
    loading,
    cellType: CellType.Frame,
    visibleItemsCount: innerWidth > 1100 ? 3 : 2,
  });

  const renderRecommendationList = useMemo(() => {
    return (
      recommendationsList && (
        <ListComponent
          component={recommendationsList}
          className="RecommendationList"
        />
      )
    );
  }, [recommendationsList]);

  const isAlbumAvailable = useMemo(() => {
    return media?.MediaTypeCode === MediaType.Album && media?.UrlSource !== "";
  }, [media?.MediaTypeCode, media?.UrlSource]);

  return (
    <MediaDetailsScreen className="AlbumDetails" media={media}>
      {media && (
        <>
          <div className="AlbumDetails__info-container">
            <div
              className={cx("AlbumDetails__info", {
                "AlbumDetails__info--flex-end": !media?.Genre,
              })}
            >
              {media?.Genre && (
                <div className="AlbumDetails__genres">
                  <span>{media.Genre}</span>
                </div>
              )}
              <div className="AlbumDetails__main-info">
                <div className="AlbumDetails__title">{media.Title}</div>
                <div
                  className="AlbumDetails__details__description-text"
                  dangerouslySetInnerHTML={{
                    __html: media.ShortDescription || "",
                  }}
                />
                <div className="AlbumDetails__actions">
                  {isAlbumAvailable && <PlayButton media={media} />}
                  {isLoggedIn && <MyListButton media={media} />}
                  <ClipboardCopyButton />
                </div>
              </div>
            </div>
          </div>
          <div className="AlbumDetails__details-container"></div>
          <SeasonEpisodesPicker
            screenType={ScreenType.AlbumDetails}
            media={media}
          />
          {renderRecommendationList}
        </>
      )}
    </MediaDetailsScreen>
  );
};
