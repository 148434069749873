/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import latinize from "latinize";

export class StringHelper {
  /*
   * Function used to generate an uniq random string
   */
  static generateRandomString = () =>
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  static toString(value: any) {
    if (value === null || value === undefined) {
      return "";
    }

    return `${value}`;
  }

  static latinize = (value: string) => latinize(value);
}
