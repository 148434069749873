/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { PlatformType } from "../enums";

export class PlatformTypeHelper {
  static getDescription(value: PlatformType): string {
    switch (value) {
      case "ANY":
        return "Any";
      case "ANDROID_PHONE":
        return "Android Phone";
      case "ANDROID_TABLET":
        return "Android Tablet";
      case "ANDROID_TV":
        return "Android TV";
      case "APPLE_TV":
        return "Apple TV";
      case "IOS_PHONE":
        return "iOS Phone";
      case "IPAD":
        return "iPad";
      case "TIZEN":
        return "Tizen";
      case "WEB":
        return "Web";
      case "WEB_OS":
        return "Web OS";
      case "ROKU":
        return "Roku";
      default:
        return "Undefined";
    }
  }

  static getValue(
    value: string,
    defaultValue: PlatformType = PlatformType.Any
  ): PlatformType {
    switch (value) {
      case "ANY":
        return PlatformType.Any;
      case "ANDROID_PHONE":
        return PlatformType.AndroidPhone;
      case "ANDROID_TABLET":
        return PlatformType.AndroidTablet;
      case "ANDROID_TV":
        return PlatformType.AndroidTV;
      case "APPLE_TV":
        return PlatformType.AppleTV;
      case "IOS_PHONE":
        return PlatformType.iOSPhone;
      case "IPAD":
        return PlatformType.iPad;
      case "TIZEN":
        return PlatformType.Tizen;
      case "WEB":
        return PlatformType.Web;
      case "WEB_OS":
        return PlatformType.WebOS;
      case "ROKU":
        return PlatformType.Roku;
      default:
        return defaultValue;
    }
  }

  static getColor(value: PlatformType): string {
    let color: string;

    switch (value) {
      case "ANY":
        color = "#1890ff";
        break;
      case "ANDROID_PHONE":
        color = "#cf1322";
        break;
      case "ANDROID_TABLET":
        color = "#cf1322";
        break;
      case "ANDROID_TV":
        color = "#ad6800";
        break;
      case "APPLE_TV":
        color = "#fadb14";
        break;
      case "IOS_PHONE":
        color = "#fa8c16";
        break;
      case "IPAD":
        color = "#13c2c2";
        break;
      case "TIZEN":
        color = "#722ed1";
        break;
      case "WEB":
        color = "#c41d7f";
        break;
      case "WEB_OS":
        color = "#8c8c8c";
        break;
      case "ROKU":
        color = "#662d91";
        break;
      default:
        color = "#2db7f5";
        break;
    }

    return color;
  }
}
