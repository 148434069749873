/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { IMediaModel, TimeHelper } from "@xala/common";
import i18n from "i18next";
import React from "react";

import "./AdditionalDetails.scss";

interface AdditionalDetailsProps {
  data: IMediaModel;
  detailsFontSize: React.CSSProperties;
  hideDate: boolean;
}

export const AdditionalDetails = ({
  data,
  detailsFontSize,
  hideDate = false,
}: AdditionalDetailsProps) => {
  return (
    <div className="AdditionalDetailsContainer">
      {!hideDate && data.AvailableFrom && (
        <p className="AdditionalDetailsContainer_Date" style={detailsFontSize}>
          {TimeHelper.getTranslatedStartDate(
            data.AvailableFrom,
            i18n.language,
            "D MMM YYYY"
          )}
        </p>
      )}
      <p
        className="AdditionalDetailsContainer_Description"
        style={detailsFontSize}
      >
        {data.ShortDescription}
      </p>
    </div>
  );
};
