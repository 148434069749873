/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum ButtonIcons {
  play = "PLAYER_PLAY_AV",
  pause = "PLAYER_PAUSE",
  forward = "PLAYER_FORWARD",
  revind = "PLAYER_PREV",
  live = "PLAYER_LIVE",
  settings = "PLAYER_SETTINGS",
  replay = "PLAYER_REPLAY",
}
