/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum PlayerCustomVidneoEvents {
  Restart = "restart",
  ToLive = "tolive",
  Backward = "backward",
  Forward = "forward",
  GoToChannel = "goToChannel",
}
