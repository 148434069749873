/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../../constants";
import { IAssetPurchasePeriodTypeModel } from "../../../../../models";
import { AxiosSubscriber, PromisifiableBase } from "../../../../../services";

export class AssetPurchasePeriodTypeService extends PromisifiableBase {
  get url(): string {
    return "/AssetPurchasePeriodTypes";
  }

  public select = (): Observable<IAssetPurchasePeriodTypeModel[]> =>
    new Observable(
      (observer: Observer<IAssetPurchasePeriodTypeModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        })
    );
}
