/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import coverPlaceholder from "./cover-placeholder.png";
import framePlaceholder from "./frame-placeholder.png";
import highlightsPlaceholder from "./highlights-placeholder.png";

export default {
  coverPlaceholder,
  framePlaceholder,
  highlightsPlaceholder,
};
