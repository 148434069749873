/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { InfoDetailsScreenCode, IUserConsentModel, ROUTES } from "@xala/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface ConsentProps {
  consent: IUserConsentModel;
}

export const Consent = ({ consent }: ConsentProps) => {
  const { t } = useTranslation();

  return (
    <>
      {consent.ConsentRequired ? (
        <span className="RequiredConsent">*</span>
      ) : (
        ""
      )}
      {consent.ConsentCode === InfoDetailsScreenCode.COOKIES ? (
        <Link
          target="_blank"
          to={`${ROUTES.PRIVACY_POLICY_SCREEN}/${consent.ConsentId}`}
        >
          <span className="link underline">
            {t("COOKIES_TITLE", "Cookies")}
          </span>
        </Link>
      ) : (
        <Link
          target="_blank"
          to={`${ROUTES.PRIVACY_POLICY_SCREEN}/${consent.ConsentId}`}
        >
          <span className="link underline">
            {t(
              `${consent.ConsentNameTranslationKey}`,
              `${consent.ConsentName}`
            )}
          </span>
        </Link>
      )}
    </>
  );
};
