/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum CellType {
  Banner = "BANNER",
  Default = "DEFAULT",
  Highlights = "HIGHLIGHTS",
  Cover = "COVER",
  Frame = "FRAME",
  Promo = "PROMO",
  Button = "BUTTON",
  Square = "SQUARE",
  Event = "EVENT",
  Round = "ROUND",
  Landscape = "LANDSCAPE",
  Channel = "CHANNEL",
  Podcast = "PODCAST",
  Custom = "CUSTOM",
}
