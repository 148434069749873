/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaButtonVariant {
  Plain = "plain",
  Primary = "primary",
  Secondary = "secondary",
  Transparent = "transparent",
  Link = "link",
  Disabled = "disabled",
  Icon = "icon",
}
