/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  CellType,
  ImageHelper,
  MediaType,
  RouteHelper,
  ThemeContext,
  TimeHelper,
  useAnalyticsContext,
} from "@xala/common";
import Color from "color";
import React, { useCallback, useContext, useRef } from "react";
import { SkeletonTheme } from "react-loading-skeleton";

import { Label } from "components";
import { ImageWithPlaceholder } from "components/ImageWithPlaceholder";
import { useElementSize, useHoverState, useStylesOnHover } from "hooks";
import resources from "resources/list";

import { IListComponentItemProps } from "../../models";

import "./ListComponentItemLandscape.scss";

const itemMargin = 12.5;

export const ListComponentItemLandscape = ({
  media,
  isActive,
}: IListComponentItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const contextType = useContext(ThemeContext);
  const themeProvider = contextType.themeProvider;

  const itemWidth = useElementSize(ref);

  const { isHover, onMouseEnter, onMouseLeave } = useHoverState();
  const { recoClick } = useAnalyticsContext();

  const stylesOnHover = useStylesOnHover(
    isActive,
    isHover,
    itemWidth,
    itemMargin
  );

  const showDurationTime = useCallback(() => {
    if (media?.Duration) {
      return `${TimeHelper.formatDuration(media?.Duration)}`;
    }

    return "";
  }, [media]);

  const showStartDateTime = useCallback(() => {
    if (
      media?.StartDateTime &&
      media.EndDateTime &&
      !TimeHelper.isBeforeCurrent(media.EndDateTime)
    ) {
      return `${TimeHelper.format(
        media.StartDateTime,
        "HH:mm"
      )}h - ${TimeHelper.format(media.EndDateTime, "HH:mm")}h`;
    }

    return "";
  }, [media]);

  const description =
    media?.MediaTypeCode === MediaType.Program ||
    media?.MediaTypeCode === MediaType.Live
      ? showStartDateTime()
      : showDurationTime();

  const overlayStyles: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: `linear-gradient(
      to bottom,
      ${Color(themeProvider.getColor("AppBackgroundColor"))
        .alpha(0)
        .darken(0)
        .string()},
      ${Color(themeProvider.getColor("AppBackgroundColor"))
        .alpha(0.8)
        .darken(0.8)
        .string()}
    )`,
  };

  const renderSkeleton = () => {
    const skeletonColor = themeProvider.getSkeletonColor();
    return (
      <div className="ListComponentItemLandscape-container ListComponentItemLandscape-container--skeleton">
        <SkeletonTheme color={skeletonColor} highlightColor={skeletonColor}>
          <div className="ListComponentItemLandscape-skeleton" />
        </SkeletonTheme>
      </div>
    );
  };
  if (!media) {
    return renderSkeleton();
  }

  const landscapeImageUrl = ImageHelper.getLandscapeImageUrl(media?.Images);

  const title = media?.Title;

  return (
    <div
      className="ListComponentItemLandscape"
      onClick={() => {
        recoClick(media);
        RouteHelper.handleClickFromListComponentItem(media);
      }}
      style={{
        ...stylesOnHover,
        marginLeft: `${itemMargin}px`,
        marginRight: `${itemMargin}px`,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      <Label media={media} />
      <div style={overlayStyles} />
      <div className="ListComponentItemLandscape-image-container">
        <ImageWithPlaceholder
          imageSrc={landscapeImageUrl}
          imageContainerClassName="ListComponentItemLandscape-image"
          placeholderSrc={resources.framePlaceholder}
          cellType={CellType.Landscape}
        />
      </div>
      <div className="ListComponentItemLandscape-information">
        <div className="ListComponentItemLandscape-information--title">
          {title}
        </div>
        {description && (
          <div className="ListComponentItemLandscape-information--description">
            {description}
          </div>
        )}
      </div>
    </div>
  );
};
