/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { withTranslation } from "react-i18next";

import { ContinueWatchingOverlay as ContinueWatchingOverlayDefinition } from "./ContinueWatchingOverlay";

export const ContinueWatchingOverlay = withTranslation()(
  ContinueWatchingOverlayDefinition
);
